import React, { useEffect } from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ButtonBAlt from "../components/ButtonBAlt"
import ScrollAnimation from "react-animate-on-scroll"
import Layout from "../components/layout"
import ScrollArrow from "../components/ScrollArrow"
import SEO from "../components/seo"
import ButtonB from "../components/ButtonB"
import "../styles/about.scss"
import Clouds from "../components/Clouds/Clouds"
import ImageMap from "image-map"
import ValuesDiagram from "../components/ValuesDiagram"

//Icons
import { AiOutlineCloudUpload } from "react-icons/ai"
import { FaLinkedin } from "react-icons/fa"

//Images
import Mik from "../images/mik-hillewaert.png"
import Rachael from "../images/rachael.jpg"
import Ghufran from "../images/ghufran-shah.png"
import Gary from "../images/gary-harrison.png"
import Philip from "../images/phillip.jpg"
import Blueprint from "../images/blueprint.jpg"
import Steve from "../images/steve-james.jpg"
import Paul from "../images/paul-thompson.jpg"
import Stewart from "../images/stewart.png"

const About = () => {
  useEffect(() => {
    ImageMap("img[usemap]")
  })
  const aboutData = useStaticQuery(graphql`
    query aboutImages {
      hero: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="About"
        description="Metsi is a global digital Systems Integrator, driving digital innovation. Metsi provides network automation and orchestration, cloud services and software-defined data centre solutions."
        lang="en-gb"
        keywords={[
          "best digital transformation consulting providers",
          "digital transformation consultancy services",
          "digital transformation consultancy uk",
        ]}
      />
      <ScrollArrow showBelow={700} />
      {/****** Hero *******/}

      <BackgroundImage
        fluid={aboutData.hero.childImageSharp.fluid}
        className="about-hero-section"
        style={{
          width: "100vw",
          height: "100vh",
          margin: 0,
          padding: 0,
          position: "relative",
          display: "flex",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content-about">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Who We Are</h1>
                  <h2>Digital disruption: changing how we live and work.</h2>
                  {/* <ButtonB href="#about-2">Our Story</ButtonB> */}
                  {/* <Clouds /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/****** History section 1 *******/}
      <div className="section" id="about-2">
        <div className="outer-container">
          <div className="inner-container">
            <h2>Our History</h2>
            <h3>Our Story, Mission and Culture</h3>
          </div>
          <div className="row" style={{ maxWidth: "1400px" }}>
            <div className="col-25">
              <div className="droplet-img" id="phone"></div>
            </div>
            <a href="" id="story" className="anchor"></a>
            <div className="col-75" id="info">
              <h2>Our story</h2>
              <p>
                Founded in 2012, Metsi is a privately owned{" "}
                <span>global digital Systems Integrator </span>
                with operations in the United Kingdom, United States, Germany,
                and The Netherlands.
              </p>
              <p>
                In the first few years of Metsi’s growth, our engineers were
                experts in
                <span>
                  {" "}
                  service catalog, infrastructure and cloud management
                </span>{" "}
                with technologies such as NewScale, Cloupia and CliQr.
              </p>
              <p>
                As a result of vendor acquisitions, Metsi built strategic
                partnerships with Cisco, Puppet Labs, and Aveksa to offer
                professional services around
                <span> automation and security</span>. Our customer success led
                Metsi to become Cisco’s first software Integrator Partner, and
                began offering <span>full-stack engineering</span> for Software
                Defined Data Centre. This laid the foundation for Metsi to
                diversify its portfolio to become a global digital Systems
                Integrator around leading edge technologies in Europe, the
                Middle East and Africa (EMEA) and the US.
              </p>
              <p>
                With the launch of Metsi US in 2018, the company expanded into{" "}
                <span> Full Stack Visibility & Observability </span> with
                AppDynamics certified engineering. In 2019, Metsi US started our
                US Federal business. Today, in partnership with the Cisco DoD
                teams, Metsi US designs, delivers, and customises IaaS and PaaS
                solutions that deliver{" "}
                <span> faster application deployment </span> for mission
                critical defense systems.
              </p>
            </div>
          </div>

          <div
            className="row"
            id="office-container"
            style={{ maxWidth: "1400px" }}
          >
            <div className="col-75" style={{ alignItems: "center" }}>
              <p>
                “Metsi” is the Tswana word for water and, like water,{" "}
                <span>we adapt</span> to conditions, environments and challenges
                as they arise.
              </p>
              <p>
                We aim to be <span>fluid, transformative, and beneficial</span>{" "}
                to all teams, technology vendors, and partners as we collaborate
                with our customers to assist them to acclimatise to the
                ever-changing demands on their digital transformation journey.
              </p>
              <p>
                After successfully delivering automation & orchestration
                services with enterprise self-service catalogues, Metsi has
                diversified to incorporate{" "}
                <span>
                  enterprise networking automation and orchestration, hybrid
                  multi-cloud, and software-defined data centre solutions.
                </span>
              </p>
              <p>
                Our advisory consultancy, professional services, alignment to
                leading technology vendors and experience in deploying the
                solutions we support serves to{" "}
                <span> minimise overall project risks</span>, while ensuring a
                comprehensive roadmap is created for our customers, in keeping
                with their required timescales and business imperatives.
              </p>
            </div>
            <div className="col-25">
              <div className="droplet-img-reverse" id="office"></div>
            </div>
          </div>
        </div>
      </div>
      {/*********** History Section 2 *********/}
      <div className="section" id="history">
        <div id="info">
          <div className="row">
            <a href="" id="mission" className="anchor"></a>
            <div
              className="column"
              style={{
                textAlign: "left",
                padding: "40px",
                paddingTop: "0",
                maxWidth: "1400px",
              }}
              id="mission"
            >
              <div style={{ marginBottom: "60px !important" }}>
                <h2>Our Mission</h2>

                <p>
                  Our mission is to be a{" "}
                  <span> leading innovative digital engineering </span> company
                  that <span> empowers modern businesses </span> to achieve
                  their digital transformation initiatives.
                </p>
                <p>
                  We deliver <span> exceptional engineering </span> on time and
                  on budget.
                </p>
                <p>
                  We <span>exceed expectations.</span>
                </p>
                <p>
                  We strive for <span> peak customer satisfaction</span>.
                  Feedback from our customers is that “the way that the Metsi
                  engineers work together as a team and focus on the solution is
                  what makes Metsi special.”
                </p>
              </div>

              <div
                style={{
                  maxWidth: "1400px",
                  alignItems: "flex-start",
                  paddingBottom: "3rem",
                }}
              >
                <div style={{ display: "block", position: "relative" }}>
                  <div className="circle-image"></div>
                  <div
                    className="column"
                    id="values"
                    style={{ display: "block" }}
                  >
                    <h2>Our Values</h2>

                    <p>
                      From the start, the Metsi team has been governed by a core
                      set of intrinsic values:
                    </p>
                    <p>
                      <span>
                        Customer Success - Our reputation is measured by the
                        success of the projects we deliver to our customers.
                      </span>
                      <ul>
                        <li>We work to exceed our customers' expectations.</li>
                        <li>
                          We take on complicated projects that others may not
                          believe there are solutions to – and work until we
                          find them.
                        </li>
                        <li>
                          We work smart and collaborate with our customers and
                          technology partners.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <span>
                        Mutual Respect - We treat our colleagues with the same
                        respect as we do our customers.
                      </span>
                      <ul>
                        <li>
                          We work across diverse teams of vendor, partner and
                          customer, where each is treated with esteem and
                          professionalism.
                        </li>
                        <li>
                          We continually work to achieve exceptional customer
                          experience.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <span>
                        Business Integrity - We are forthright, with respect,
                        transparency and integrity.
                      </span>
                      <ul>
                        <li>
                          We pride ourselves on being easy to work with, by
                          being adaptable to changing requirements and business
                          dynamics.
                        </li>
                        <li>
                          We seek to be fluid and transformative, providing
                          benefit across our customers’ and the technology
                          eco-systems.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <span>Family First - Family must come first</span>
                      <ul>
                        <li>
                          Personal happiness comes from harmony at home, in the
                          workplace and through life's experiences. Family must
                          come first.
                        </li>
                        <li>
                          Metsi individuals have a responsibility to have each
                          other’s back.
                        </li>
                        <li>
                          Our business's growth is achievable when each employee
                          knows they are part of a community that cares about
                          them.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        href=""
        id="culture"
        className="anchor"
        style={{ marginTop: "-60px" }}
      ></a>
      <ValuesDiagram />

      {/*<div>
                  <h2 style={{ marginTop: "3rem" }}>Our Values</h2> 
                  
                  <p>
                    Throughout our growth, the Metsi team is governed by an
                    adherence to a core set of intrinsic values:
                  </p>
                  <p>
                    <strong>
                      Customer Success - Our reputation is measured only by the
                      success of the projects we deliver to our customers.
                    </strong>
                    <ul>
                      <li>
                        We work above and beyond to exceed our customers'
                        expectations.
                      </li>
                      <li>
                        We are the team that delivers complicated solutions that
                        others don't want to tackle or believe is not possible.
                      </li>
                      <li>
                        We operate smartly and collaboratively with our
                        customers and technology partners.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>
                      Mutual Respect - We treat our colleagues with the same
                      respect as we do our customers.
                    </strong>
                    <ul>
                      <li>
                        We work across diverse teams of vendor, partner and
                        customer, where each is treated with respect and
                        professionalism.
                      </li>
                      <li>
                        We work towards a common goal of exceptional customer
                        experience.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>
                      Business Integrity - We say it how it is with respect,
                      transparency and integrity.
                    </strong>
                    <ul>
                      <li>
                        We pride ourselves on being easy to transact with while
                        being adaptable to changing requirements and business
                        dynamics.
                      </li>
                      <li>
                        We seek to be fluid, transformative, and providing
                        benefit across our customers and technology eco-system.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <strong>Family First - Family must come first</strong>
                    <ul>
                      <li>
                        Personal happiness comes from harmony at home, in the
                        workplace and through life's experiences.
                      </li>
                      <li>
                        Each Metsi individual has a responsibility for having
                        each other’s back.
                      </li>
                      <li>
                        Our business's growth is achievable when each employee
                        knows they are part of a community that cares about
                        them.
                      </li>
                    </ul>
                  </p> 
                  </div>
                  </div>
        </div>
      </div>
                  */}

      {/* </div> */}

      {/*********** Vortex *********/}
      {/* <div className="section" id="vortex">
        <div className="outer-container">
          <div className="column">
            <p>
              Working like an extension to your team Metsi help customers and
              partners through uncertain times to stay ahead of the curve.
              Through a shared vision of success, Metsi provide the solid
              foundation for your digital transformation journey.
            </p>
            <p>
              <strong>
                Metsi Technologies brand continues to gather momentum and is
                synonymous with <br></br>
                <span>
                  “Project success”, technical excellence and agility.
                </span>
              </strong>
            </p>
          </div>
        </div>
      </div> */}
      {/*********** Outcomes *************/}

      {/* 
      <a href="" id="blueprint" className="anchor"></a>
      <div className="outcomes-section" id="blueprint"> */}

      {/*<InteractiveImageDemo />*/}
      {/*<div
       style={{
         position: "relative",
         paddingBottom: "55%",
         paddingTop: 0,
         height: 0,
       }}
     >
       <iframe
         style={{
           position: "absolute",
           top: "0",
           left: "0",
           width: "100%",
           height: "100%",
           borderWidth: "0px",
           maxWidth: "100%",
           overflowY: "auto",
         }}
         width="100%"
         height="100%"
         src="https://interactive-img.com/view?id=24276&iframe=true"
       ></iframe>
       </div>*/}

      {/* <div className="outer-container">
          <div className="inner-container">
            <h2>Outcomes for our customers</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1>The Metsi Blueprint behind our business</h1>
            </ScrollAnimation>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maxime
              quia fuga corporis laboriosam voluptates sapiente fugit dolor
              doloribus! Est, ea iure amet inventore id ipsam molestiae. Quidem
              dolorum laboriosam beatae, quaerat impedit aspernatur ducimus et,
              iure rerum omnis at quam saepe nesciunt corrupti nihil.
              Perspiciatis possimus pariatur cum temporibus blanditiis?
            </p>
          </div>

          <img src={Blueprint} alt="blueprint" useMap="#image-map" />
          <map name="image-map">
            <area
              className="area"
              target=""
              alt="Visibility-and-insights"
              title="Visibility-and-insights"
              href="/visibility-and-insights"
              coords="389,230,348,322,782,322,824,230,782,135,346,137"
              shape="poly"
            />

            <area
              target=""
              alt="Business-insights"
              title="Business-insights"
              href="/visibility-and-insights#business-insights"
              coords="352,348,352,484,754,486,824,418,754,348"
              shape="poly"
            />
          </map>
        </div>
      </div> */}

      {/*********** Team *********/}
      <a href="" id="team" className="anchor"></a>
      <div className="section" id="team">
        <div className="outer-container">
          {/* <h2>Journey of transformation</h2> */}
          <h3>Executive team</h3>
          <div className="row member">
            <div className="col-25">
              <img src={Mik} alt="Mik" className="team-image" />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Mik Hillewaert</span> - Global Chief Executive Officer{" "}
                </h4>
                <Link
                  to="https://www.linkedin.com/in/michael-hillewaert-2bb2441/?originalSubdomain=be"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>
              <p>
                Mik Hillewaert has over 20 years of experience in the technology
                industry, from Benelux Regional Manager at PTC to Regional
                Director Eastern Region for VMware. In 2011 Mik was appointed as
                VP of International Sales at VirtualSharp Software, a
                next-generation Disaster Recovery company acquired by PHD
                Virtual Technologies in March 2013. In 2012 Mik founded Nubera
                to provide IT services to the Benelux market. Mik became CEO of
                Metsi Technologies in 2013 and Global CEO in 2018.
              </p>
            </div>
          </div>
          {/* <div className="row member">
            <div className="col-25">
              <img
                src={Rachael}
                alt="Rachel"
                className="team-image"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Rachael Babcock</span> - US Chief Executive Officer
                </h4>
                <Link
                  to="https://www.linkedin.com/in/rachaelbabcock/"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>
              <p>
                Rachael Babcock is an accomplished Go-To-Market CEO who is
                passionate about the people, processes, and technology of
                innovation. Her career, spanning more than 2 decades and 3
                continents, has encompassed engineering, technical consulting,
                and sales and business development in IT & telecommunications.
                Rachael also has a breadth of experience in executive
                leadership, having served in the roles of CEO, CINO, and CCO
                across the globe. She has built an innovative fintech startup,
                led statewide digital transformation, and scaled a nationwide
                $100 million Cisco VAR business. Rachael believes in a high
                growth, high impact team culture built on collaboration,
                productivity, and accountability, with teams that are both
                empowered and empathic. She is focused on customer value,
                long-term thinking, and operational excellence.
              </p>
            </div>
          </div> */}
          <div className="row member">
            <div className="col-25">
              <img src={Ghufran} alt="Ghufran" className="team-image" />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Ghufran Shah</span> - Global Chief Operating Officer
                </h4>
                <Link
                  to="https://www.linkedin.com/in/ghufranshah/"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>

              <p>
                In 2012, Ghufran Shah co-founded Metsi Technologies to provide
                customers with a comprehensive strategic IT roadmap, which
                allows them to tie investment in IT services directly to
                business outcomes. With over 20 years’ experience in IT,
                including service management, hybrid multicloud, and automation,
                Ghufran’s passion is leveraging IT capabilities to deliver
                operational efficiency with innovative solutions. As co-author
                of 8 IBM Redbooks in enterprise service management, he has
                worked globally with clients to deliver value-based IT
                solutions. Ghufran enjoys cycling and regularly engages with his
                local university by setting real-world employer challenges to
                law students.
              </p>
            </div>
          </div>

          {/* <div className="row member">
            <div className="col-25">
              <img
                src={Philip}
                alt="Philip"
                className="team-image"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Phillip Odom</span> - US Chief Technology Officer
                </h4>
                <Link
                  to="https://www.linkedin.com/in/phillipodom/"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>

              <p>
                Phillip’s 25+ year career encompasses hardware design, software
                development, full stack performance engineering, and Fortune 500
                consulting. With a background in Electrical Engineering,
                Computer Science, and Bioinformatics, Phillip has found himself
                at the nexus of technology and entrepreneurship. He has designed
                a host of products and solutions, including wearable medical
                devices, remote access devices, iBeacon/BLE tracking, and IoT
                virtualisation solutions. Phillip has also been a founder, board
                member and senior advisor in multiple start-ups with successful
                exits. Phillip joined Metsi US shortly after it acquired his APM
                company in 2018 and has since built Metsi’s Full Stack
                Visibility and Advanced DevOps practices. Phillip’s core focus
                is ensuring that Metsi continues to deliver the best technology
                experience for its customers, delivering solutions that
                continuously exceed expectations.
              </p>
            </div>
          </div> */}

          <div className="row member">
            <div className="col-25">
              <img src={Gary} alt="Gary" className="team-image" />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Gary Harrison</span> - EMEA Chief Technology Officer
                </h4>
                <Link
                  to="https://www.linkedin.com/in/gary-harrison-6084382/"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>

              <p>
                Gary Harrison brings more than 25 years of experience in media,
                fintech, and telecommunications industries to Metsi. He is
                passionate about proactively driving disruption in business
                through the adoption of cloud technologies, including
                Software-Defined Data Centre (SDDC), Software-Defined Networking
                (SDN) / Access (SDA) / WAN (SD-WAN), and XaaS (as-a-Service). In
                addition to his leadership as Principal Architect for Enterprise
                Networks at Sky TV, Gary was a senior consultant for Thomson
                Reuters’ Infrastructure-as-a-Service (IaaS) and Data Centre
                Rationalisation program. Gary started his career in R&D for
                military communications in Australia and the United States, and
                then led technology strategy in various startup roles, including
                Infrastructure and Development Director and Lead Infrastructure
                Architect.
              </p>
            </div>
          </div>

          <div className="row member">
            <div className="col-25">
              <img src={Stewart} alt="Stewart" className="team-image" />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Stewart Beaumont</span> - Non-executive Member of Board
                  of Directors
                </h4>
                <Link
                  to="https://www.linkedin.com/in/stewartbeaumont/"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>

              <p>
                Stewart Beaumont is a seasoned executive whose illustrious
                career has seen him build and support a wide array of products
                across many different industries, including news, legal and
                financial markets. Stewart is currently an executive with
                Thomson Reuters, an organisation he joined in 1999. Over the
                course of his 2-decade history with Thomson Reuters, he has held
                several crucial positions, including Global Head of Divisional
                Technology Group and Chief Technology Officer. Since March 2021,
                he has served as Vice Chairman & President, Enterprise Centre.
                In this role, Beaumont is responsible for group strategy,
                including inorganic investments, and commercial strategy.
              </p>
            </div>
          </div>

          {/* <div className="row member">
            <div className="col-25">
              <img
                src={Steve}
                alt="Steve"
                className="team-image"
                style={{ borderRadius: "50%" }}
              />
            </div>

            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Steve James</span> - Co-founder
                </h4>
                <Link
                  to="https://www.linkedin.com/in/steve-james-6bab2a9"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>

              <p>
                Steve James has more than thirty years of IT experience within
                and across customers, software vendors and partners. As a serial
                entrepreneur, co-founder, executive of both locally and globally
                built businesses, he remains intrinsically involved with the
                broader sales and technical teams in the construction, alignment
                to a customers business, and IT challenges while delivering a
                value proposition intended to provide differentiation to our
                clients.
              </p>
              <p>
                With a core focus on the adoption of technologies and a strong
                passion for driving the company's technology direction,
                marketing and branding, building a winning team and culture that
                directly increases the profitability to shareholders and
                provides a sustainable platform for growth.
              </p>
            </div>
          </div> */}

          <div className="row member">
            <div className="col-25">
              <img
                src={Paul}
                alt="Paul"
                className="team-image"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <div className="col-75">
              <div className="title-row">
                <h4>
                  <span>Paul Thompson</span> - Co-founder
                </h4>
                <Link
                  to="https://www.linkedin.com/in/paul-thompson-07400638/"
                  target="_blank"
                >
                  <FaLinkedin className="linkedin" />
                </Link>
              </div>

              <p>
                Paul Thompson is a veteran of the IT industry. Having started
                his career as a Systems Engineer writing applications in
                Assembler and the like, he eventually gravitated to the business
                aspect of the industry. Paul had numerous roles in Sales before
                progressing to sales leadership and ultimately business
                leadership. He specialised in the start-up of both Hardware and
                Software companies throughout his career, totalling nine of them
                through the years.
              </p>
              <p>
                Paul spent 13 years working in Europe, again in the world of
                Start-up companies generally driving Venture Capital companies
                through to levels of maturity and market momentum across the
                European markets. He was involved in the start-up of companies
                such as Sun Microsystems, Marimba Inc, White Cross Systems to
                name a few. Paul also had a stint at Tivoli Systems looking
                after their EMEA markets. Paul speaks 4 languages. He returned
                to South Africa in 2004 to start Puleng Technologies.
              </p>
              <p>
                Paul was the CEO of Puleng Technologies for 17 years growing the
                business exponentially before it was sold. He has now invested
                in a number of Start-up companies and provides leadership to
                these companies.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*********** Get In Touch *********/}
      <div className="section" id="get-in-touch">
        <div className="overlay">
          <div className="outer-container">
            <h2>Get In Touch</h2>
            <h3>Have a question about our services?</h3>
            <ButtonBAlt href="/contact">Contact Us</ButtonBAlt>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
